import {
  PERMISSION_VIEW_COUNTERPART,
  PERMISSION_VIEW_CONTRACT,
  PERMISSION_VIEW_OPERATION,
  PERMISSION_VIEW_MONITORING,
  PERMISSION_VIEW_DECLINED_PAYMENT,
  PERMISSION_VIEW_REPORT,
  PERMISSION_VIEW_TASK,
  PERMISSION_VIEW_FINANCIAL_MODEL,
  PERMISSION_VIEW_USER,
} from "@/constants";

export default {
  items: [
    {
      name: "Головна",
      url: "/main",
      srcImg: "/images/nav-home.svg",
      badge: {
        variant: "primary",
      },
    },
    {
      name: "Контрагенти",
      url: "/entity/counterpart",
      srcImg: "/images/nav-counterparties.svg",
      can: PERMISSION_VIEW_COUNTERPART,
    },
    {
      name: "Договори",
      url: "/entity/contract",
      srcImg: "/images/nav-contracts.svg",
      can: PERMISSION_VIEW_CONTRACT,
    },
    {
      name: "Реєстри",
      url: "/v2/reyestry",
      srcImg: "/images/nav-contracts.svg",
      can: PERMISSION_VIEW_CONTRACT,
    },
    {
      name: "Поточний моніторинг",
      url: "/monitoring",
      srcImg: "/images/nav-watchman-monitoring.svg",
      can: PERMISSION_VIEW_MONITORING,
    },
    {
      name: "Моніторинг карток",
      url: "/cards-monitoring",
      srcImg: "/images/nav-unique-cards.svg",
      can: PERMISSION_VIEW_MONITORING,
    },
    {
      name: "Моніторинг договорів",
      url: "/contracts-monitoring",
      srcImg: "/images/nav-contract-monitoring.svg",
      can: PERMISSION_VIEW_MONITORING,
    },
    {
      name: "Операції",
      url: "/operations",
      srcImg: "/images/nav-operations.svg",
      can: PERMISSION_VIEW_OPERATION,
    },
    {
      name: "Ліміти та контроль",
      url: "/declined-payments",
      srcImg: "/images/nav-limits.svg",
      can: PERMISSION_VIEW_DECLINED_PAYMENT,
    },
    {
      name: "Статзвітність",
      url: "/reporting",
      srcImg: "/images/nav-reporting.svg",
      can: PERMISSION_VIEW_REPORT,
    },
    {
      name: "Нормативи НБУ",
      url: "/regulations",
      srcImg: "/images/nav-nbu.svg",
      can: PERMISSION_VIEW_REPORT,
      canTenant: "aifintech",
    },
    {
      name: "Дії над контрагентами",
      url: "/entity/tasks",
      srcImg: "/images/nav-contracts.svg",
      can: PERMISSION_VIEW_TASK,
    },
    {
      name: "Профілі СПФМ",
      url: "/entity/financial-models",
      srcImg: "/images/nav-finance.svg",
      can: PERMISSION_VIEW_FINANCIAL_MODEL,
    },
    {
      name: "Користувачі",
      url: "/entity/users",
      srcImg: "/images/nav-users.svg",
      can: PERMISSION_VIEW_USER,
    },
  ],
};
